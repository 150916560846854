<template>
  <div class="main">
    <div class="banner">
      <img src="../assets/imgs/AIMicroBanner.png" width="100%" alt="AI微认证" />
      <div class="bannerText">
        <h1>AI微认证</h1>
        <p>AI微认证人工智能能力标化测评中心旨在建立一套面向K12的标准人工智能知识的分级测评体系。</p>
        <a href="https://aitech.qingsteam.cn/ai/AI%E5%BE%AE%E8%AE%A4%E8%AF%81.exe" download  class="btn"></a>
      </div>
    </div>
    <div class="microDiv">
      <p>AI微认证</p>
      <img src="../assets/imgs/line.png" class="line">
      <div class="AIMicroCircle">
        <div class="text text_left">
          <p>测评形式为选择题+程序纠错题+任务题+线下实操题<img src="../assets/imgs/dot.png" class="dot"></p>
          <p>线上测评+线下实操测评的综合评分成绩<img src="../assets/imgs/dot.png" class="dot"></p>
          <p>成绩达标，获得本级测评证书<img src="../assets/imgs/dot.png" class="dot"></p>
        </div>
        <img src="../assets/imgs/AIMicroCircle.png" alt="">
        <div class="text text_right">
          <p><img src="../assets/imgs/dot.png" class="dot">旨在建立面向K12的标准人工智能知识的分级测评体系</p>
          <p><img src="../assets/imgs/dot.png" class="dot">针对人工智能基础知识测评，包括教学、思维、编程、</p>
          <p>人工智能等领域</p>
        </div>
      </div>
      <span>人工智能测评系统</span>
    </div>
    <div class="microDiv" style="margin-top:90px">
      <p>阶梯式成长课程设计</p>
      <img src="../assets/imgs/line.png" class="line">
      <span>全面提升孩子综合素养</span>
      <img src="../assets/imgs/AIMicroTable.png" class="AIMicroTable">
    </div>
  </div>
</template>
<script>
import $ from 'jquery'
export default {
  data(){
    return{
      win_H:0,
      scrollTop:0,
      fade_Off:0,
      fade_Start:0
    }
  },
}
</script>
<style lang="scss" scoped>
.main {
  background-color: #F8F9FA;
  .banner {
    width: 100%;
    margin-bottom: 65px;
    position: relative;
    .bannerText{
      display: flex;
      flex-direction: column;
      width: 28%;
      position: absolute;
      top: 15%;
      left: 18.5%;
      h1{
        font-size: 62px;
        color: #FFFFFF;
      }
      p{
        font-size: 18px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 30px;
        margin: 23px 0 36px 0;
      }
      .btn{
        width: 210px;
        height: 63px;
        cursor: pointer;
        background: url('../assets/imgs/AIMicroDownload.png') no-repeat;
        background-size: 100% 100%;
        background-size: 100% 100%;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
      }
    }
  }
  @media screen and (max-width: 1360px) {
  .banner {
    .bannerText{
      h1{
        font-size: 40px;
      }
      p{
        font-size: 14px;
        margin: 17px 0;
      }
      .btn{
        width: 130px;
        height: 48px;
      }
    }
  }
}

@media (min-width: 1360px) and (max-width: 1440px) {
.banner {
    .bannerText{
      h1{
        font-size: 52px;
      }
      p{
        font-size: 16px;
        margin: 23px 0 26px 0;
      }
      .btn{
        width: 170px;
        height: 50px;
      }
    }
}
}
  .microDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p{
      font-size: 40px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333A48;
      line-height: 56px;
      margin-bottom: 10px;
    }
    .line{
      width: 70px;
      height: 3px;
    }
    .AIMicroCircle{
      margin-top: 62px;
      display: flex;
      align-items: center;
      .text{
        width: 445px;
        margin-top: 50px;
        p{
          font-size: 17px;
          font-weight: 500;
          color: #6A6A6A;
          line-height: 42px;
        }
        .dot{
          width: 9px;
          height: 9px;
          margin: 3px;
        }
      }
      .text_left{
        text-align: right;
        margin-right: 20px;
      }
      .text_right{
        margin-left: 20px;
      }
      img{
        width: 322px;
        height: 321px;
      }
    }
    span{
      height: 33px;
      font-size: 24px;
      font-weight: 400;
      color: #9AA7B9;
      line-height: 33px;
      margin-top: 23px;  
    }
    .AIMicroTable{
      width: 1162px;
      height: 1591px;
      margin-bottom: 22px;
    }
  }
}
</style>